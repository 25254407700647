<!--
 * @Author: your name
 * @Date: 2021-04-24 18:40:35
 * @LastEditTime: 2021-06-06 22:25:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \vr-portal\src\components\page\page.vue
-->
<template>
  <div class="vrPage" v-if="total !== 0">
    <div class="vrPage-pre" v-if="pageIndex !== 1">
      <img :src="require('@imgs/components/leftArr.svg')" />
    </div>
    <div :class="{'vrPage-item':true,'vrPage-item--active': pageIndex === (index+1)}" v-for="(item,index) in pageCount" :key="index" @click="toPage(index+1)">{{index+1}}</div>
    <div class="vrPage-aft" v-if="pageIndex !== pageCount">
      <img :src="require('@imgs/components/rightArr.svg')" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'Page',
  props: {
    total: {
      type: Number,
      default: 0
    },
    pageSize:{
      type:Number,
      default: 8
    }
  },
  data () {
    return {
      pageIndex: 1,
      pageRender: []
    }
  },
  computed: {
    pageCount () {
      return Math.ceil(this.total / this.pageSize)
    }
  },
  methods: {
    nextPage () {
      if (this.pageIndex === this.pageCount) {
        return
      }
      this.toPage(this.pageIndex + 1)
    },
    prePage () {
      if (this.pageIndex === 1) {
        return
      }
      this.toPage(this.pageIndex - 1)
    },
    toPage (pageIndex) {
      this.pageIndex = pageIndex
      this.$emit('pageChange', pageIndex)
    }
  }
}
</script>
<style scoped lang="scss">
.vrPage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  &-pre,
  &-aft {
    width: 13px;
    height: 13px;
    border-radius: 13px;
    background: #d8d8d8;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    line-height: 12px;
    img {
      width: 10px;
      height: 10px;
    }
  }
  &-item {
    width: 16px;
    height: 16px;
    border-radius: 16px;
    font-size: 12px;
    text-align: center;
    line-height: 16px;
    margin: 0 5px;
    cursor: pointer;
    font-family: Microsoft YaHei;
  }
  &-item--active {
    background: #0862ca;
    color: #fff;
  }
}
</style>