<!--
 * @Author: your name
 * @Date: 2021-04-24 18:32:35
 * @LastEditTime: 2021-06-20 23:02:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \vr-portal\src\components\new\dynamic.vue
-->
<template>
  <div class="new row" @click="toDetail">
    <div class="new-img" v-if="!$isMobile()">
      <img :src="newInfo.cover" />
    </div>
    <div class="new-message">
      <div class="new-content">
        <div class="new-title l-1-overflow">{{newInfo.title}}</div>
        <div class="new-detail l-2-overflow">
          {{newInfo.content | getContent}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'dynamic',
  props: {
    newInfo: {
      type: Object,
      default () {
        return {
          cover: require('@imgs/WechatIMG437.png'),
          createTime: '20201-04-30',
          title: '2021新冠肺炎疫情防控',
          content: '自新型冠状病毒肺炎疫情蔓延以来，VR联盟协会（简称VR联盟）迅速进入“战时”状态，协会领导高度重视，积极应对，协会各部门充分发挥作用，协同各市、州协会及会员单位积极采取措施对VR联盟协会（简称VR联盟）炎疫情蔓延州协会及会员单位积极采取措施对VR联盟协会（简称VR联盟）炎疫情蔓延州协会及会员单位积极采取措施对VR联盟协会（简称VR联盟）炎疫情蔓延州协会及会员单位积极采取措施对VR联盟协会（简称VR联盟）炎疫情蔓延'
        }
      }
    }
  },
  data () {
    return {
      newList: (localStorage.getItem('dynewList') || '').split(',')
    }
  },
  filters: {
    getContent (v) {
      let dom = document.createElement('div')
      dom.innerHTML = v
      return dom.innerText
    }
  },
  methods: {
    toDetail () {
      this.$router.push({
        path: '/new-detail',
        query: {
          id: this.newInfo.id,
          type: '2'
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.new {
  align-items: center;
  display: flex;
  &-img {
    display: flex;
    //justify-content: center;
    align-items: center;
    img {
      width: 190px;
      height: 130px;
    }
  }
  &-message {
    flex:1;
    display: flex;
    height: 130px;
    align-items: center;
    &:hover, &:active{
      background: #f5f5f5;
    }
    overflow: hidden;
  }
  &-content {
    padding: 0 0 0 12px;
    overflow: hidden;
  }
  &-title {
    font-size: 20px;
    font-weight: 400;
    color: #333333;
    cursor: pointer;
    font-family: Microsoft YaHei;
  }
  &-title-detail {
    color: #d5d5d5;
  }
  &-detail {
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    font-size: 14px;
    font-family: Microsoft YaHei;
  }
}
</style>