
<template>
  <div class="swiper-container" :id="`swiper-${id}`">
    <slot name="buttonPre"></slot>
    <div :class="['swiper-wrapper',swiperClass]">
      <slot></slot>
    </div>
    <slot name="pagination"></slot>
    <slot name="buttonNext"></slot>
  </div>
</template>
<script>
import Swiper, { Autoplay, Pagination, Thumbs, Navigation } from 'swiper'
Swiper.use([Autoplay, Pagination, Thumbs, Navigation])
import 'swiper/swiper.scss'
export default {
  name: 'swiper',
  props: {
    autoplay: {
      type: [Boolean, Object],
      default () {
        return {
          delay: 3000,//1秒切换一次
        }
      }
    },
    direction: {
      type: String,
      default: 'horizontal'
    },
    options: {
      type: Object,
      default () {
        return {}
      }
    },
    autoInit: {
      type: Boolean,
      default: true
    },
    swiperClass:{
      type:String,
      default: ''
    }
  },
  mounted () {
    if (this.autoInit) {
      this.init()
    }

  },
  data () {
    return {
      swiper: null,
      id: new Date().getTime().toString() + (Math.floor(Math.random() * 99) + 1).toString()
    }
  },
  methods: {
    init (options) {
      this.swiper = new Swiper(`#swiper-${this.id}`, {
        loop: true,
        autoplay: this.autoplay,
        direction: this.direction,
        ...this.options,
        ...options
      })
      return this.swiper
    },
    next () {
      this.swiper.slideNext()
    },
    pre () {
      this.swiper.slidePrev()
    },
    getSwiper () {
      return this.swiper
    },
    setConfig (config) {
      this.$nextTick(() => {
        this.swiper = new Swiper(`#swiper-${this.id}`, {
          loop: true,
          autoplay: this.autoplay,
          direction: this.direction,
          ...this.options,
          ...config
        })
      })

    }
  }
}
</script>

<style>
.swiper-slide-thumb-active {
  border: 2px solid #ed0c0c;
}
</style>