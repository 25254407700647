<!--
 * @Author: your name
 * @Date: 2021-03-29 09:28:32
 * @LastEditTime: 2021-06-20 23:24:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \vr-portal\src\App.vue
-->
<template>
  <div id="app" class="layout">
    <vr-header class="layout-nav" @hide="hide" @showLoginModal="showLoginModal"
      @showRegisterModal="showRegisterModal"></vr-header>
    <div :style="$isMobile() ? 'height:40px;' : 'height:138px;'"></div>
    <div class="layout-content" @click="hide">
      <keep-alive :include="['VRBuild', 'home', 'VRClass']">
        <router-view />
      </keep-alive>
    </div>
    <vr-footer class="layout-footer"></vr-footer>
    <login-modal ref="loginModal"></login-modal>
    <register-modal ref="registerModal"></register-modal>
  </div>
</template>
<script>
import loginModal from '@components/modal/login-modal';
import registerModal from '@components/modal/register-modal';
import vrFooter from '@components/footer'
import vrHeader from '@components/header'
export default {
  name: "app",
  components: {
    vrHeader,
    vrFooter,
    loginModal,
    registerModal
  },
  methods: {
    hide() {
      document.getElementById('navbarSupportedContent').setAttribute('class', 'navbar-collapse nav-background collapse')
    },
    showLoginModal() {
      this.$refs.loginModal.showNormalModal();
    },
    showRegisterModal() {
      this.$refs.registerModal.showModal();
    },
  }
}
</script>
<style lang="scss">
html,
body {
  height: 100%;
  line-height: 1;
  font-family: PingFangSC-Medium, PingFang SC;
  background: #f4f4f4;
}

.body {
  overflow-y: scroll;
}

.layout {
  height: 100%;

  &-nav {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
  }

  &-content {
    min-height: 100%;
  }

  &-footer {}
}
</style>
