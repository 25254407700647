<template>
  <div class="vrBuild-item2" @click="toDetail">
    <div class="vrBuild-img2">
      <img :src="info.cover" />
    </div>
    <p class="text-one-overflow">{{info.title}}</p>
  </div>
</template>
<script>
export default {
  name: "brBuildItem2",
  props: {
    info: {
      type: Object,
      default () {
        return {}
      }
    },
    type:{
      type:String,
      default: ''
    }
  },
  methods: {
    toDetail () {
      this.$router.push({
        path: '/vr-detail',
        query:{
          type:this.type,
          id:this.info.id
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.vrBuild-item2 {
  width: 367px;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #eeeeee;
  cursor: pointer;
  p {
    text-align: center;
    width: 100%;
    padding: 15px 10px 18px;
    box-sizing: border-box;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 16px;
    color: #666666;
    line-height: 18px;
    text-align: center;
    font-style: normal;
    margin-bottom: 0;
  }
}
.vrBuild-img2 {
  width: 367px;
  height: 260px;
  img {
    width: 100%;
    height: 100%;
  }
}
.vrBuild-item:hover {
  box-shadow: 0px 1px 11px 0px rgba(0, 0, 0, 0.2);
}
.vrBuild-item span:hover{
  color: #2870b6;
  cursor: pointer;
}
</style>