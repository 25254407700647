<template>
    <div class="loginModal-content">
        <div class="loginModal-header">
            <div class="loginModal-header-line">
                <p v-if="download">如下载文件，需先登录网站</p>
                <p v-else></p>
                <button type="button" class="close loginModal-header-line-close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <p class="loginModal-header-title" id="loginModalLabel">登录</p>
        </div>
        <div class="loginModal-body">
            <form @submit.prevent="submitForm" class="loginModal-body-form">
                <div class="loginModal-body-form-group">
                    <label for="username">账号</label>
                    <input type="text" id="username" v-model="username" required placeholder="请输入账号">
                </div>
                <div class="loginModal-body-form-line"></div>
                <div class="loginModal-body-form-group">
                    <label for="password">密码</label>
                    <input type="password" id="password" v-model="password" required placeholder="请输入密码">
                </div>
                <div class="loginModal-body-form-line"></div>
                <div class="loginModal-body-form-group">
                    <label for="captcha">验证码</label>
                    <input type="text" id="captcha" v-model="captcha" required placeholder="请输入验证码">
                    <img :src="'data:image/jpeg;base64,' + captchaData.img" @click="refreshCaptcha" alt="">
                </div>
                <div class="loginModal-body-form-line" style="margin-bottom: 10px;"></div>
                <div class="forget" @click="forgotPassword">忘记密码？</div>
                <button type="submit" class="btn">立即登录</button>
            </form>
        </div>
    </div>
</template>
<script>
import Bus from '@/components/bus.js'
export default {
    name: "loginModal",
    data() {
        return {
            username: '',
            password: '',
            captcha: '',
            captchaData: { img: '', uuid: '' } // 假设这个API返回一个图形验证码的图片
        }
    },
    created() {
        this.refreshCaptcha();
    },
    props: ['changeModal', 'hideModal', 'download'],
    methods: {
        submitForm() {
            const formData = {
                username: this.username,
                password: this.password,
                code: this.captcha,
                uuid: this.captchaData.uuid
            };
            this.$http2({
                url: "/login",
                data: formData,
                method: 'post'
            }).then((res) => {
                if (res.code === 500) {
                    this.$toast.error(res.msg, {
                        timeout: 1500
                    });
                } else {
                    localStorage.setItem('authToken', "Bearer " + res.token)
                    this.$toast.info(res.msg, {
                        timeout: 1000
                    });
                    this.hideModal()
                    setTimeout(() => {
                        Bus.$emit('changeLogin');
                    }, 10);
                }
            }).catch((err) => {
                this.$toast.error(err.toString(), {
                    timeout: 1500
                });
                if (err.toString().includes("验证码已失效")) this.refreshCaptcha()
            });
        },
        refreshCaptcha() {
            this.$http2({
                url: "/captchaImage",
            }).then((res) => {
                this.captchaData = { img: res.img, uuid: res.uuid }
            });
        },
        forgotPassword() {
            this.changeModal("passwordModal")
        }
    }
}
</script>
<style scoped lang="scss">
.modal-dialog {
    margin-top: 100px;
    background: #FFFFFF;
    box-shadow: 0px 9px 22px 7px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    max-width: 700px;

    .loginModal-content {
        padding: 24px;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        outline: 0;
    }
}

.loginModal-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-line {
        display: flex;
        width: 100%;
        justify-content: space-between;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 16px;
        color: #5B5959;
        line-height: auto;

        &-close {
            margin: -24px -24px 0 0;
            width: 48px;
            height: 48px;
            background: #FFFFFF;
            box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.13);
            border-radius: 50%;
            border: 1px solid #EFEEEE;

            span {
                font-weight: 600;
                font-size: 20px;
            }
        }
    }

    &-title {
        font-size: 32px;
        line-height: normal;
        font-weight: 600;
        color: #595555;
        font-family: Microsoft YaHei;
        margin: 0;

        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 6px;
            background: #2770B5;
            border-radius: 3px;
        }
    }
}

.loginModal-body {
    margin-top: 50px;
    width: 100%;
    padding: 40px;
    box-sizing: border-box;

    &-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-group {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            position: relative;

            label {
                width: 70px;
                font-family: Microsoft YaHei;
                font-weight: 500;
                font-size: 20px;
                line-height: normal;
                color: #595555;
                margin: 0;
            }

            input {
                flex: 1;
                margin: 0 20px 0 50px;
                border: none;
                background: none;
                outline: none;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #3c3c3c;
                padding: 0;

                &::placeholder {
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    font-size: 16px;
                    color: #C1BCBC;
                    letter-spacing: normal;
                    margin: 0;
                    padding: 0;
                }
            }

            img {
                position: absolute;
                right: 20px;
                height: 36px;
                width: 96px;
                top: calc(50% - 19px);
                cursor: pointer;
            }
        }

        .forget {
            line-height: normal;
            color: #2770B5;
            font-family: Microsoft YaHei;
            margin: 0;
            width: 100%;
            text-align: end;
            font-weight: 400;
            font-size: 15px;
            cursor: pointer;

            &:hover {
                font-weight: 600;
                color: #2983d7;
            }
        }

        .btn {
            margin-top: 30px;
            width: 200px;
            height: 50px;
            background: #2770B5;
            border-radius: 25px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 18px;
            color: #FFFFFF;
            box-shadow: inset 0px 7px 14px 0px rgba(0, 0, 0, 0.46);

            &:hover {
                box-shadow: 0px 7px 14px 0px rgba(0, 0, 0, 0.46);
            }

            &:active {
                box-shadow: none;
            }
        }

        &-line {
            display: block;
            margin-bottom: 30px;
            margin-top: 5px;
            width: 100%;
            height: 3px;
            background: #B6B5B0;
            opacity: 0.34;

        }
    }
}
</style>