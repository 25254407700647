var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vrBuild"},[_c('div',{staticClass:"vrBuild-tab"},[_c('div',{class:{
      'vrBuild-tab-item': true,
      'vrBuild-tab-item--active': _vm.vrTabItem === '人才培养研究',
    },on:{"click":function($event){return _vm.tabChange('人才培养研究')}}},[_vm._v(" 人才培养研究 ")]),_c('div',{class:{
      'vrBuild-tab-item': true,
      'vrBuild-tab-item--active': _vm.vrTabItem === 'VR教材建设',
    },on:{"click":function($event){return _vm.tabChange('VR教材建设')}}},[_vm._v(" VR教材建设 ")]),_c('div',{class:{
      'vrBuild-tab-item': true,
      'vrBuild-tab-item--active': _vm.vrTabItem === 'VR师资培训',
    },on:{"click":function($event){return _vm.tabChange('VR师资培训')}}},[_vm._v(" VR师资培训 ")]),_c('div',{class:{
      'vrBuild-tab-item': true,
      'vrBuild-tab-item--active': _vm.vrTabItem === 'VR专业课程教学平台',
    },on:{"click":function($event){return _vm.tabChange('VR专业课程教学平台')}}},[_vm._v(" VR专业课程教学平台 ")])]),(_vm.vrTabItem === '人才培养研究')?[_c('div',{staticClass:"vrBuild-tab-content"},_vm._l((_vm.vrItems),function(item,index){return _c('div',{key:_vm.key[_vm.vrTabItem] + index,staticClass:"vrBuild-tab-box col-sm-4 col-12"},[_c('vr-item2',{key:_vm.key[_vm.vrTabItem] + index,staticClass:"vrBuild-tabItem",attrs:{"info":item,"type":_vm.vrTabItem}})],1)}),0)]:_vm._e(),([
    'VR教材建设',
    'VR师资培训',
    'VR专业课程教学平台' ].includes(_vm.vrTabItem)
    )?[_c('div',{staticClass:"vrBuild-tab-content"},_vm._l((_vm.vrItems),function(item,index){return _c('div',{key:_vm.key[_vm.vrTabItem] + index,staticClass:"vrBuild-tab-box col-sm-3 col-12"},[_c('vr-item',{key:_vm.key[_vm.vrTabItem] + index,staticClass:"vrBuild-tabItem",attrs:{"info":item,"type":_vm.vrTabItem}})],1)}),0)]:_vm._e(),(_vm.isPage)?_c('page',{attrs:{"total":_vm.total},on:{"pageChange":_vm.getList}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }