<template>
    <div class="modal fade" id="loginModal" tabindex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <component :is="currentModal" :changeModal="changeModal" :hideModal="hideModal" :download="download"></component>
        </div>
    </div>
</template>
<script>
import passwordModal from './password-modal';
import loginContentModal from './loginContent-modal';
import Bus from '@/components/bus.js'
export default {
    name: "loginModal",
    components: {
        passwordModal,
        loginContentModal
    },
    data() {
        return {
            download: false,
            isVisible: true,
            currentModal: 'loginContentModal'
        }
    },
    created() {
        Bus.$on('showDownloadModal', this.showDownloadModal);
        Bus.$on('showNormalModal', this.showNormalModal);
    },
    beforeDestroy() {
        Bus.$off('showDownloadModal', this.showDownloadModal);
        Bus.$off('showNormalModal', this.showNormalModal);
    },
    methods: {
        changeModal(modal) {
            this.currentModal = modal;
        },
        showNormalModal() {
            $('#loginModal').modal('show');
            this.download = false;
        },
        showDownloadModal() {
            $('#loginModal').modal('show');
            this.download = true;
        },
        hideModal() {
            $('#loginModal').modal('hide');
        }
    }
}
</script>
<style scoped lang="scss">
.modal-dialog {
    margin-top: 100px;
    background: #FFFFFF;
    box-shadow: 0px 9px 22px 7px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    max-width: 700px;

    .loginModal-content {
        padding: 24px;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        outline: 0;
    }
}
</style>