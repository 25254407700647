var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vr-header"},[(!_vm.$isMobile())?_c('div',{staticClass:"vr-header-logo",staticStyle:{"padding":"20px 0"}},[_c('img',{staticStyle:{"height":"60px"},attrs:{"src":require('@imgs/logo.png')},on:{"click":function($event){return _vm.$router.push({ path: '/' })}}}),(!_vm.login)?_c('div',{staticClass:"vr-header-sign"},[_c('div',{staticClass:" vr-header-sign-item",on:{"click":_vm.showLoginModal}},[_vm._v(" 登录 ")]),_c('p',[_vm._v("|")]),_c('div',{staticClass:" vr-header-sign-item",on:{"click":_vm.showRegisterModal}},[_vm._v(" 注册 ")])]):_c('div',{staticClass:"vr-header-hadLog"},[_c('p',[_vm._v("已登录，")]),_c('div',{staticClass:" vr-header-hadLog-item",on:{"click":_vm.logout}},[_vm._v(" 注销 ")])])]):_vm._e(),_c('div',{class:{ 'vr-header-nav': true, 'vr-header-nav-mobile': _vm.$isMobile() }},[_c('nav',{staticClass:"navbar navbar-expand-lg navbar-light vr-header-nav-content"},[(_vm.$isMobile())?_c('a',{staticClass:"navbar-brand",attrs:{"href":"#"}},[_c('img',{staticStyle:{"width":"156px","height":"auto"},attrs:{"src":require('@imgs/logo.png')},on:{"click":function($event){return _vm.$router.push({ path: '/' })}}})]):_vm._e(),_vm._m(0),_c('div',{staticClass:"collapse navbar-collapse nav-background",attrs:{"id":"navbarSupportedContent"},on:{"click":_vm.hide}},[_c('ul',{staticClass:"navbar-nav mr-auto"},[_c('router-link',{attrs:{"to":"/"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isExactActive = ref.isExactActive;
return [_c('li',{class:{ 'nav-item': true, 'nav-item--active': isExactActive },on:{"click":function($event){return _vm.toPage('/')}}},[_c('a',[_vm._v("首页")])])]}}])}),_c('router-link',{attrs:{"to":"/new"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isExactActive = ref.isExactActive;
return [_c('li',{class:{ 'nav-item': true, 'nav-item--active': isExactActive },on:{"click":function($event){return _vm.toPage('new')}}},[_c('a',[_vm._v("新闻资讯")])])]}}])}),_c('router-link',{attrs:{"to":"/people"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isExactActive = ref.isExactActive;
return [_c('li',{class:{ 'nav-item': true, 'nav-item--active': isExactActive },on:{"click":function($event){return _vm.toPage('people')}}},[_c('a',[_vm._v("专家介绍")])])]}}])}),_c('router-link',{attrs:{"to":"vr-build"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isExactActive = ref.isExactActive;
return [_c('li',{class:{ 'nav-item': true, 'nav-item--active': isExactActive },on:{"click":function($event){return _vm.toPage('vr-build')}}},[_c('a',[_vm._v("VR专业建设")])])]}}])}),_c('router-link',{attrs:{"to":"vr-major"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isExactActive = ref.isExactActive;
return [_c('li',{class:{ 'nav-item': true, 'nav-item--active': isExactActive },on:{"click":function($event){return _vm.toPage('vr-major')}}},[_c('a',[_vm._v("VR专业介绍")])])]}}])}),_c('router-link',{attrs:{"to":"vr-teach"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isExactActive = ref.isExactActive;
return [_c('li',{class:{ 'nav-item': true, 'nav-item--active': isExactActive },on:{"click":function($event){return _vm.toPage('vr-teach')}}},[_c('a',[_vm._v("教VR-企业范例")])])]}}])}),_c('router-link',{attrs:{"to":"vr-taught"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isExactActive = ref.isExactActive;
return [_c('li',{class:{ 'nav-item': true, 'nav-item--active': isExactActive },on:{"click":function($event){return _vm.toPage('vr-taught')}}},[_c('a',[_vm._v("VR教-企业范例")])])]}}])}),_c('router-link',{attrs:{"to":"aubot"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isExactActive = ref.isExactActive;
return [_c('li',{class:{ 'nav-item': true, 'nav-item--active': isExactActive },on:{"click":function($event){return _vm.toPage('aubot')}}},[_c('a',[_vm._v("工委会建设")])])]}}])}),_c('router-link',{attrs:{"to":"download"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isExactActive = ref.isExactActive;
return [_c('li',{class:{ 'nav-item': true, 'nav-item--active': isExactActive },on:{"click":function($event){return _vm.toPage('download')}}},[_c('a',[_vm._v("下载中心")])])]}}])})],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarSupportedContent","aria-controls":"navbarSupportedContent","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})])}]

export { render, staticRenderFns }