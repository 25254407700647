var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vr-footer"},[_c('div',{staticClass:"vr-footer-info row"},[_c('div',{staticClass:"row"},[_c('router-link',{attrs:{"to":"/"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isExactActive = ref.isExactActive;
return [_c('div',{class:{ 'vr-footer-text': true, 'vr-footer-text--active': isExactActive },on:{"click":function($event){return _vm.toPage('/')}}},[_c('span',[_vm._v("首页")])])]}}])}),_c('router-link',{attrs:{"to":"/new"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isExactActive = ref.isExactActive;
return [_c('div',{class:{ 'vr-footer-text': true, 'vr-footer-text--active': isExactActive },on:{"click":function($event){return _vm.toPage('/new')}}},[_c('span',[_vm._v("新闻资讯")])])]}}])}),_c('router-link',{attrs:{"to":"/people"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isExactActive = ref.isExactActive;
return [_c('div',{class:{ 'vr-footer-text': true, 'vr-footer-text--active': isExactActive },on:{"click":function($event){return _vm.toPage('/people')}}},[_c('span',[_vm._v("专家介绍")])])]}}])}),_c('router-link',{attrs:{"to":"/vr-build"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isExactActive = ref.isExactActive;
return [_c('div',{class:{ 'vr-footer-text': true, 'vr-footer-text--active': isExactActive },on:{"click":function($event){return _vm.toPage('/vr-build')}}},[_c('span',[_vm._v("VR专业建设")])])]}}])}),_c('router-link',{attrs:{"to":"vr-major"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isExactActive = ref.isExactActive;
return [_c('div',{class:{ 'vr-footer-text': true, 'vr-footer-text--active': isExactActive },on:{"click":function($event){return _vm.toPage('/vr-major')}}},[_c('span',[_vm._v("VR专业介绍")])])]}}])}),_c('router-link',{attrs:{"to":"vr-teach"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isExactActive = ref.isExactActive;
return [_c('div',{class:{ 'vr-footer-text': true, 'vr-footer-text--active': isExactActive },on:{"click":function($event){return _vm.toPage('vr-teach')}}},[_c('span',[_vm._v("教VR-企业范例")])])]}}])}),_c('router-link',{attrs:{"to":"vr-taught"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isExactActive = ref.isExactActive;
return [_c('div',{class:{ 'vr-footer-text': true, 'vr-footer-text--active': isExactActive },on:{"click":function($event){return _vm.toPage('vr-taught')}}},[_c('span',[_vm._v("VR教-企业范例")])])]}}])}),_c('router-link',{attrs:{"to":"aubot"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isExactActive = ref.isExactActive;
return [_c('div',{class:{ 'vr-footer-text': true, 'vr-footer-text--active': isExactActive },on:{"click":function($event){return _vm.toPage('/aubot')}}},[_c('span',[_vm._v("工委会建设")])])]}}])}),_c('router-link',{attrs:{"to":"download"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isExactActive = ref.isExactActive;
return [_c('div',{class:{ 'vr-footer-text': true, 'vr-footer-text--active': isExactActive },on:{"click":function($event){return _vm.toPage('/download')}}},[_c('span',[_vm._v("下载中心")])])]}}])})],1)]),_c('div',{staticClass:"vr-footer-cr vr-footer-cr--sm"},[_c('img',{attrs:{"src":require('@imgs/beian.png')}}),_c('div',{staticStyle:{"width":"10px"}}),_c('a',{attrs:{"href":"https://www.beian.gov.cn/portal/recordQuery?token=9d113844-b64b-47f8-85ff-748b7916e8d3"}},[_vm._v("京公网安备 11011402011361号")]),_c('div',{staticStyle:{"width":"30px"}}),_c('span',[_vm._v("京ICP备2021015329号-1")])])])}
var staticRenderFns = []

export { render, staticRenderFns }