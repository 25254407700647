/*
 * @Author: your name
 * @Date: 2021-03-29 09:28:32
 * @LastEditTime: 2021-06-16 22:14:27
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \vr-portal\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@bootstrap/css/bootstrap.min.css'
import '@css/common.css'
import axios from './axios/axios'
import axios2 from './axios/axios2'
// import '@css/animate.min.css'
// import 'jquery'
// import 'popper.js'
import '@bootstrap/js/bootstrap.min.js'
import '@css/bubble.css'
import $ from 'jquery'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
const options = {
  // You can set your default options here
};
Vue.use(Toast, options);
Vue.config.productionTip = false
Vue.prototype.$http=axios
Vue.prototype.$http2=axios2
Vue.prototype.$isMobile = () =>{
  return window.document.body.clientWidth < 900
}
new Vue({
  router,
  $,
  render: h => h(App)
}).$mount('#app')
