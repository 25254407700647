/*
 * @Author: your name
 * @Date: 2021-03-29 11:12:45
 * @LastEditTime: 2021-06-16 22:49:07
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \vr-portal\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/aubot',
    name: 'Aubot',
    component: () => import('../views/aubot/aubot'),
  },
  {
    path: '/vr-build',
    name: 'VrBuild',
    component: () => import('../views/vrbuild/vr-build'),
  },
  {
    path: '/vr-detail',
    name: 'VrDetail',
    component: () => import('../views/vrbuild/vr-build-detail.vue'),
  },
  {
    path: '/application',
    name: 'application',
    component: () => import('../views/application/application'),
  },
  {
    path: '/application-detail',
    name: 'applicationDetail',
    component: () => import('../views/application/application-detail'),
  },
  {
    path: '/integration',
    name: 'Integration',
    component: () => import('../views/Integration/Integration'),
  },
  {
    path: '/new',
    name: 'New',
    component: () => import('../views/new/new'),
  },
  {
    path: '/new-detail',
    name: 'newDetail',
    component: () => import('../views/new/new-detail'),
  },
  {
    path: '/people',
    name: 'People',
    component: () => import('../views/people/people'),
  },
  {
    path: '/people-detail',
    name: 'PeopleDetail',
    component: () => import('../views/people/people-detail.vue'),
  },
  {
    path: '/vr-class',
    name: 'VRClass',
    component: () => import('../views/vrClass/vr-class'),
  },
  {
    path: '/vr-teach',
    name: 'VRTeach',
    component: () => import('../views/vrTeach/vr-teach'),
  },
  {
    path: '/vr-teach-detail',
    name: 'vrTeachDetail',
    component: () => import('../views/vrTeach/vr-teach-detail'),
  },
  {
    path: '/vr-major',
    name: 'VRMajor',
    component: () => import('../views/vrMajor/vr-major'),
  },
  {
    path: '/vr-major-detail',
    name: 'vrMajorDetail',
    component: () => import('../views/vrMajor/vr-major-detail'),
  },
  {
    path: '/vr-taught',
    name: 'VRTaught',
    component: () => import('../views/vrTaught/vr-taught'),
  },
  {
    path: '/vr-taught-detail',
    name: 'VRTaughtDetail',
    component: () => import('../views/vrTaught/vr-taught-detail'),
  },
  {
    path: '/vr-class-detail',
    name: 'VRClassDetail',
    component: () => import('../views/vrClass/vr-class-detail.vue'),
  },
  {
    path: '/download',
    name: 'Download',
    component: () => import('../views/download/download'),
  },
  {
    path: '/notice-detail',
    name: 'noticeDetail',
    component: () => import('../views/notice/notice-detail.vue'),
  },
]

const router = new VueRouter({
  routes,
})

export default router
