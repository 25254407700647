<!--
 * @Author: your name
 * @Date: 2021-06-01 19:40:44
 * @LastEditTime: 2021-06-16 23:22:29
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \vr-portal\src\views\new\new-content.vue
-->
<template>
  <div class="newContent">
    <!-- <div class="newContent-tab">
      <div :class="{ 'newContent-tab-item': true, 'newContent-tab-item--active': newTabItem === '工委会要闻' }"
        @click="change('工委会要闻')">工委会要闻</div>
      <div :class="{ 'newContent-tab-item': true, 'newContent-tab-item--active': newTabItem === '行业资讯' }"
        @click="change('行业资讯')">行业资讯</div>
    </div> -->
    <template v-if="newTabItem === '工委会要闻'">
      <new-item v-for="(item) in news" :key="item.id" :newInfo="item" style="margin-top:30px;cursor: pointer;"></new-item>
    </template>
    <template v-if="newTabItem === '行业资讯'">
      <!-- 行业咨询 -->
      <dynamic v-for="(item) in news" :key="item.id" :newInfo="item" style="margin-top:30px;cursor: pointer;"></dynamic>
    </template>
    <page v-if="isPage" :total="total" @pageChange="getList"></page>
  </div>
</template>
<script>
import newItem from '@components/new/new.vue'
import dynamic from '@components/new/dynamic.vue'
import page from '@components/page/page.vue'
export default {
  props: {
    isPage: {
      type: Boolean,
      default: true
    }
  },
  components: {
    newItem,
    dynamic,
    page
  },
  data() {
    return {
      newTabItem: '行业资讯',
      news: [],
      total: 0
    }
  },
  created() {
    this.getList(1)
  },
  methods: {
    change(item) {
      this.newTabItem = item
      this.$emit('change', item)
      this.getList(1)
    },
    getList(pageNum) {
      this.$http({
        // url: this.newTabItem === '工委会要闻' ? '/special_committee_news/list' : '/news/list',
        url: '/special_committee_news/list',
        params: {
          pageNum,
          pageSize: 10
        }
      }).then(res => {
        this.news = res.rows
        this.total = res.total
      })
    }
  }
}
</script>
<style scoped lang="scss">
.newContent {
  &-tab {
    display: flex;

    &-item {
      padding: 5px 16px;
      font-size: 16px;
      border: 1px solid #eee;
      color: #666666;
      cursor: pointer;
      font-family: Microsoft YaHei;
    }

    &-item--active {
      background: #2870b6;
      color: #fff;
      border: 0;
    }
  }
}</style>