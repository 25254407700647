/*
 * @Author: your name
 * @Date: 2021-04-29 21:58:03
 * @LastEditTime: 2021-06-20 22:42:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \vr-portal\src\axios\axios.js
 */
/*
 * @Description:
 */
/*
 * @Description: 统一的请求
 */
import axios from 'axios'

export const baseURL = '/api'
const http = axios.create({
  baseURL,
  timeout: 180000,
})
// 请求前的钩子函数
http.interceptors.request.use(
  function(config) {
    config.headers['Authorization'] = localStorage.getItem('authToken') || ''
    return config
  },
  function(error) {
    return Promise.reject(error)
  },
)
// 请求后的钩子函数
http.interceptors.response.use(
  function(res) {
    if (res.data) {
      if (res.data instanceof Blob) return Promise.resolve(res.data)
      switch (res.data.code) {
        case 200:
          return Promise.resolve(res.data)
        case 401:
          router.push({
            path: '/login',
          })
          return Promise.reject(new Error('登录过期'))
        default:
          return Promise.reject(new Error(res.data.message || res.data.msg))
      }
    } else {
      return Promise.reject(new Error('未知错误'))
    }
  },
  function(error) {
    return Promise.reject(error)
  },
)

export default http
