<!--
 * @Author: your name
 * @Date: 2021-03-29 15:32:48
 * @LastEditTime: 2021-06-20 23:39:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \vr-portal\src\views\home\home.vue
-->
<template>
  <div class="home">
    <div>
      <swiper class="home-swiper" :options="swiperOptions">
        <swiper-slide class="home-swiper-item">
          <img :src="require('@imgs/home/banner.png')" v-if="$isMobile()" />
          <img :src="require('@imgs/home/web/banner.png')" v-else />
        </swiper-slide>
        <div class="home-swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <div class="home-content">
      <!-- 热点 -->
      <div class="home-hot">
        <div class="home-hot-title">
          <span>
            热点
          </span>
        </div>
        <swiper direction="vertical" :autoplay="false" :autoInit="false" ref="notice" class="home-hot-swiper">
          <swiper-slide class="home-hot-item" v-for="(item, index) in notices" :key="index">
            <div class="row home-hot-li">
              <div class="col-sm-6 col-12 text-one-overflow" v-for="(_item, _index) in item" :key="_index"
                @click="toNoticeDetail(_item.id)">
                {{ _item.title }}
              </div>
            </div>
          </swiper-slide>
          <swiper-slide v-if="notices.length % 2 !== 0"></swiper-slide>
        </swiper>
        <div class="home-hot-page">
          <img :src="require('@imgs/searchback.png')" @click="hotPrePage" />
          <img :src="require('@imgs/listmore.png')" @click="hotNextPage" />
        </div>
      </div>
      <!-- 新闻资讯 -->
      <div class="home-block">
        <div class="home-block-title">
          <div class="home-block-new-title">专委会要闻</div>
          <div class="home-block-new-more" @click="$router.push({ path: '/new' })">更多</div>
        </div>
        <new-content :isPage="false"></new-content>
      </div>
      <!-- 专家介绍 -->
      <div class="home-block">
        <div class="home-block-title">
          <div class="home-block-new-title">专家介绍</div>
          <div class="home-block-new-more" @click="$router.push({ path: '/people' })">更多</div>
        </div>
        <div class="row home-pages">
          <div class="home-pages-item" @click="prePage">
            <img :src="require('@imgs/searchback.png')" />
          </div>
          <div class="home-pages-item" @click="nextPage">
            <img :src="require('@imgs/listmore.png')" />
          </div>
        </div>
        <swiper :autoplay="true" ref="people" :autoInit="false">
          <swiper-slide v-for="(item) in peoples" :key='item.expertId' style="display:flex;justify-content:center;">
            <people-item :info="item"></people-item>
          </swiper-slide>
        </swiper>
      </div>
      <!-- vr专业建设 -->
      <div class="home-block">
        <div class="home-block-title">
          <div class="home-block-new-title">VR专业建设</div>
          <div class="home-block-new-more" @click="$router.push({ path: '/vr-build' })">更多</div>
        </div>
        <div style="height:20px;"></div>
        <vr-build :isPage="false"></vr-build>
      </div>
      <!-- VR实验室建设 -->
      <!-- <div class="home-block">
        <div class="home-block-title">
          <div class="home-block-new-title">VR应用中心</div>
          <div class="home-block-new-more" @click="$router.push({path:'/application'})">更多</div>
        </div>
        <div class="home-block-tab-content">
          <div class="home-block-box col-sm-3 col-12" v-for="(item) in applications" :key="item.id">
            <application-item class="home-block-tabItem" :info="item"></application-item>
          </div>
        </div>
      </div> -->
      <div style="height:40px;"></div>
    </div>
  </div>
</template>
<script>
import swiper from '@components/swiper/swiper'
import swiperSlide from '@components/swiper/swiperSlide'
import homeLogin from './login.vue'
import newContent from '@/views/new/new-content'
import applicationItem from '@/views/application/components/application-item'
import vrBuild from '@/views/vrbuild/vr-build-content'
import peopleItem from '@/views/people/people-item'
import PeopleItem from '../people/people-item.vue'
export default {
  name: 'home',
  components: {
    swiper,
    swiperSlide,
    homeLogin,
    applicationItem,
    vrBuild,
    newContent,
    peopleItem,
    PeopleItem
  },
  data() {
    return {
      mySwiper: null,
      swiperOptions: {
        autoplay: true,
        pagination: {
          el: '.home-swiper-pagination',
          clickable: true,
          bulletClass: 'home-swiper-pagination--default',//需设置.my-bullet样式
          bulletActiveClass: 'home-swiper-pagination--active'
        },
      },
      vrItem: {
        label: 'VR实训室',
        key: 'classroom',
        img: require('@imgs/classhome.png'),
        tip: '虚拟现实专业人才培养基地”是为了虚拟现实技术专业量身打造的集学习科技',
        more: ''
      },
      peoples: [],
      newTabItem: '工委会要闻',
      applications: [],
      notices: []
    }
  },
  computed: {
    peopSwiperOption() {

    }
  },
  created() {
    this.getPeople()
    this.getNotice()
    this.getApplication()
  },
  activated() {
    this.$refs.people.init(this.$isMobile() ? {
      slidesPerView: 1,
      autoplay: true,
      loop: false
    } : {
      slidesPerView: 4, loop: false
    })
  },
  methods: {
    toNoticeDetail(id) {
      this.$router.push({
        path: '/notice-detail',
        query: {
          id
        }
      })
    },
    vrChange(item) {
      this.vrItem = item
    },
    nextPage() {
      this.$refs.people.next()
    },
    prePage() {
      this.$refs.people.pre()
    },
    getPeople() {
      this.$http({
        url: '/expert/list',
        params: {
          pageNum: 1,
          pageSize: 99999
        }
      }).then(res => {
        this.peoples = res.rows
        this.$nextTick(() => {
          this.$refs.people.init(this.$isMobile() ? {
            slidesPerView: 1,
            autoplay: true,
            loop: false
          } : {
            slidesPerView: 5, loop: false, autoplay: true
          })
        })
      })
    },
    hotPrePage() {
      this.$refs.notice.pre()
    },
    hotNextPage() {
      this.$refs.notice.next()
    },
    getNotice() {
      this.$http({
        url: '/notice/list',
        params: {
          pageNum: 1,
          pageSize: 10
        }
      }).then(res => {
        const iconsArr = []; // 声明数组
        let arr = res.rows || []
        arr.forEach((item, index) => {
          const page = Math.floor(index / 2); // 计算该元素为第几个素组内
          if (!iconsArr[page]) { // 判断是否存在
            iconsArr[page] = [];
          }
          iconsArr[page].push(item);
        });
        this.notices = iconsArr
        this.$nextTick(() => {
          this.$refs.notice.init({
            slidesPerView: this.$isMobile() ? 1 : 2
          })
        })
      })
    },
    getApplication() {
      this.$http({
        url: '/center/list',
        params: {
          pageNum: 1,
          pageSize: 4
        }
      }).then(res => {
        this.applications = res.rows || []
        this.total = res.total
      })
    }
  }
}
</script>
<style lang="scss">
.home {
  &-swiper-pagination {
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 999;
    bottom: 20px;
    left: 0;
    right: 0;

    &--default {
      background: #ffffff;
      opacity: 0.6;
      border-radius: 4px;
      display: inline-block;
      width: 20px;
      height: 8px;
      margin-right: 10px;
    }

    &--active {
      background: #ffffff;
      opacity: 1;
    }
  }
}
</style>
<style scoped lang="scss">
.home {
  &-swiper-item {
    background: #f5f5f5;
    position: relative;

    img {
      width: 100%;
    }
  }

  &-swiper {
    width: 100%;
  }

  &-content {
    max-width: 1200px;
    margin: 0 auto;
  }

  &-hot {
    margin-top: 25px;
    display: flex;
    background: #fff;

    &-title {
      width: 64px;
      height: 80px;
      background: #cd1414;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 33px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Microsoft YaHei;

      span {
        display: inline-block;
        width: 24px;
      }
    }

    &-item {
      padding: 0 12px;
      display: flex;
      flex-direction: column;
      align-content: center;
    }

    &-swiper {
      flex: 1;
      height: 80px;
    }

    &-li {
      display: flex;
      align-items: center;
      cursor: pointer;

      div {
        padding-top: 10px;
      }
    }

    &-page {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 12px;

      img {
        width: 18px;
        transform: rotate(90deg);
        // margin-bottom:10px;
      }
    }
  }

  &-aubot {
    padding: 24px 12px;

    &-title {
      font-size: 24px;
      font-weight: 600;
      color: #333333;
      line-height: 40px;
      text-align: center;
      font-family: Microsoft YaHei;
    }

    &-tip {
      font-size: 14px;
      font-weight: 500;
      color: #666666;
      line-height: 20px;
      text-align: center;
      padding-top: 10px;
      font-family: Microsoft YaHei;
    }

    &-text {
      font-size: 16px;
      font-weight: 400;
      color: #666660;
      line-height: 32px;
      font-family: Microsoft YaHei;
    }
  }

  &-block {
    margin-top: 20px;
    background: #fff;
    padding: 24px;

    &-box {
      display: flex;
      justify-content: center;
    }

    &-tab {
      display: flex;

      &-item {
        padding: 5px 16px;
        font-size: 16px;
        border: 1px solid #eee;
        color: #666666;
        cursor: pointer;
        font-family: Microsoft YaHei;
      }

      &-item--active {
        background: #2870b6;
        color: #fff;
        border: 0;
      }
    }

    &-tab-content {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
    }

    &-tabItem {
      margin-top: 15px;
    }

    &-title {
      padding-bottom: 12px;
      border-bottom: 1px solid #eee;
      justify-content: space-between;
      display: flex;
    }

    &-img {
      width: 100%;
      position: relative;

      img {
        width: 100%;
        //height: 518px;
      }

      &-tip {
        background: rgba(0, 0, 0, 0.4);
        color: #fff;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 150px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 0 24px;
      }

      &-title {
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        font-family: Microsoft YaHei;
      }

      &-tag {
        font-size: 12px;
        font-weight: 500;
        padding: 5px 10px;
        border-radius: 8px;
        background: #0060af;
        font-family: Microsoft YaHei;
      }
    }

    &-new {
      &-title {
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
        position: relative;
        padding-left: 10px;
        font-family: Microsoft YaHei;
      }

      &-title::before {
        content: "";
        width: 4px;
        border-radius: 2px;
        height: 20px;
        left: 0px;
        position: absolute;
        background: #0862ca;
        display: block;
        top: 3px;
      }

      &-more {
        font-size: 16px;
        font-weight: 400;
        color: #999999;
        line-height: 22px;
        cursor: pointer;
        font-family: Microsoft YaHei;
      }
    }
  }

  &-pages {
    padding: 20px 0;
    justify-content: flex-end;

    &-item {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-item:hover,
    &-item:active {
      background: #0862ca;
    }

    img {
      width: 12px;
    }
  }
}
</style>