<template>
    <div class="loginModal-content">
        <div class="loginModal-header">
            <div class="loginModal-header-line">
                <button type="button" class="loginModal-header-line-back" @click="back">
                    <span aria-hidden="true">&lt;</span>
                </button>
            </div>
            <p class="loginModal-header-title" id="loginModalLabel">忘记密码</p>
        </div>
        <div class="loginModal-body">
            <form @submit.prevent="sendVerificationCode" class="loginModal-body-form">
                <div class="loginModal-body-form-group">
                    <label for="email">邮箱</label>
                    <input type="text" id="email" v-model="email" required placeholder="请输入邮箱">
                </div>
                <div class="loginModal-body-form-line" style="margin-bottom: 10px;"></div>
                <button type="submit" class="btn">{{ label }}</button>
            </form>
        </div>
    </div>
</template>
<script>
export default {
    name: "loginModal",
    data() {
        return {
            isVisible: true,
            email: '',
            code: '',
            countdown: 0,
        }
    },
    computed: {
        cursorStyle() {
            return this.countdown > 0 ? 'not-allowed' : 'pointer';
        },
        buttonColor() {
            return this.countdown > 0 ? '#999' : '#2770B5';
        },
        label() {
            return this.countdown > 0 ? `${this.countdown}秒后可重新发送` : "发送新密码";
        },
    },
    created() {
    },
    props: ['changeModal', 'hideModal'],
    methods: {
        sendVerificationCode() {
            if (this.email.length === 0) {
                this.$toast.error('邮箱为空！', {
                    timeout: 1500
                });
                return;
            }
            const emailRegEx = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/;
            //检查邮箱是否是地为邮箱
            if (!emailRegEx.test(this.email)) {
                this.$toast.error('邮箱格式不正确!', {
                    timeout: 1500
                });
                return;
            }
            this.$http2({
                url: "/system/user/resetPwdByEmail",
                params: {
                    email: this.email
                },
                method: 'get'
            }).then((res) => {
                console.log(res);
                if (res.code === 500) {
                    this.$toast.error(res.msg, {
                        timeout: 1500
                    });
                } else {
                    this.$toast.info("新密码已成功发送，请前往邮箱查看", {
                        timeout: 2000
                    });
                    this.countdown = 60;
                    const interval = setInterval(() => {
                        this.countdown -= 1;
                        if (this.countdown <= 0) {
                            clearInterval(interval);
                        }
                    }, 1000);
                    this.changeModal('loginContentModal')
                }
            }).catch((err) => {
                this.$toast.error(err.toString(), {
                    timeout: 1500
                });
            });
        },
        back() {
            this.changeModal('loginContentModal')
        }
    }
}
</script>
<style scoped lang="scss">
.modal-dialog {
    margin-top: 100px;
    background: #FFFFFF;
    box-shadow: 0px 9px 22px 7px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    max-width: 700px;

    .loginModal-content {
        padding: 24px;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        outline: 0;
    }
}

.loginModal-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-line {
        display: flex;
        width: 100%;
        justify-content: space-between;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 16px;
        color: #5B5959;
        line-height: auto;

        &-back {
            margin: -24px 0 0 -24px;
            width: 48px;
            height: 48px;
            background: #FFFFFF;
            box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.13);
            border-radius: 50%;
            border: 1px solid #EFEEEE;

            span {
                font-weight: 600;
                font-size: 20px;
            }
        }
    }

    &-title {
        font-size: 32px;
        line-height: normal;
        font-weight: 600;
        color: #595555;
        font-family: Microsoft YaHei;
        margin: 0;

        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 6px;
            background: #2770B5;
            border-radius: 3px;
        }
    }
}

.loginModal-body {
    margin-top: 50px;
    width: 100%;
    padding: 40px;
    box-sizing: border-box;

    &-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-group {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            position: relative;

            label {
                width: 70px;
                font-family: Microsoft YaHei;
                font-weight: 500;
                font-size: 20px;
                line-height: normal;
                color: #595555;
                margin: 0;
            }

            input {
                flex: 1;
                margin: 0 20px 0 50px;
                border: none;
                background: none;
                outline: none;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #3c3c3c;
                padding: 0;

                &::placeholder {
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    font-size: 16px;
                    color: #C1BCBC;
                    letter-spacing: normal;
                    margin: 0;
                    padding: 0;
                }
            }

            &-code {
                position: absolute;
                right: 20px;
                height: 22px;
                line-height: 22px;
                top: calc(50% - 11px);
                font-size: 16px;
                line-height: normal;
                font-weight: 600;
                font-family: Microsoft YaHei;
                margin: 0;
            }
        }

        .forget {
            line-height: normal;
            color: #2770B5;
            font-family: Microsoft YaHei;
            margin: 0;
            width: 100%;
            text-align: end;
            font-weight: 400;
            font-size: 15px;
            cursor: pointer;

            &:hover {
                font-weight: 600;
                color: #2983d7;
            }
        }

        .btn {
            margin-top: 30px;
            width: 200px;
            height: 50px;
            background: #2770B5;
            border-radius: 25px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 18px;
            color: #FFFFFF;
            box-shadow: inset 0px 7px 14px 0px rgba(0, 0, 0, 0.46);

            &:hover {
                box-shadow: 0px 7px 14px 0px rgba(0, 0, 0, 0.46);
            }

            &:active {
                box-shadow: none;
            }
        }

        &-line {
            display: block;
            margin-bottom: 30px;
            margin-top: 5px;
            width: 100%;
            height: 3px;
            background: #B6B5B0;
            opacity: 0.34;

        }
    }
}
</style>