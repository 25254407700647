<!--
 * @Author: your name
 * @Date: 2021-03-29 15:59:20
 * @LastEditTime: 2021-06-06 23:05:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \vr-portal\src\components\header.vue
-->
<template>
  <div class="vr-header">
    <div class="vr-header-logo" style="padding:20px 0;" v-if="!$isMobile()">
      <img :src="require('@imgs/logo.png')" style="height:60px;" @click="$router.push({ path: '/' })" />
      <!-- <div class="vr-header-apply">
        <div class=" vr-header-apply-item" style="margin-right:12px;" @click="$router.push({path:`/aubot`,query:{type:'委员申请'}})">
          <img :src="require('@imgs/activityApply@3.png')" />
          委员申请
        </div>
        <div class=" vr-header-apply-item"  @click="$router.push({path:`/aubot`,query:{type:'活动申请'}})">
          <img :src="require('@imgs/comMember@3.png')" />
          活动申请
        </div>
      </div> -->
      <div v-if="!login" class="vr-header-sign">
        <div class=" vr-header-sign-item" @click="showLoginModal">
          登录
        </div>
        <p>|</p>
        <div class=" vr-header-sign-item" @click="showRegisterModal">
          注册
        </div>
      </div>
      <div v-else class="vr-header-hadLog">
        <p>已登录，</p>
        <div class=" vr-header-hadLog-item" @click="logout">
          注销
        </div>
      </div>
    </div>
    <div :class="{ 'vr-header-nav': true, 'vr-header-nav-mobile': $isMobile() }">
      <nav class="navbar navbar-expand-lg navbar-light vr-header-nav-content">
        <a class="navbar-brand" href="#" v-if="$isMobile()">
          <img :src="require('@imgs/logo.png')" style="width:156px;height:auto;" @click="$router.push({ path: '/' })" />
        </a>
        <!-- <div class=" vr-header-apply-item" style="margin-right:12px;width:60px;height:24px;font-size:12px;" @click="$router.push({path:`/aubot`,query:{type:'委员申请'}})" v-if="$isMobile()">
          委员申请
        </div>
        <div class=" vr-header-apply-item" style="width:60px;height:24px;font-size:12px;" @click="$router.push({path:`/aubot`,query:{type:'活动申请'}})" v-if="$isMobile()">
          活动申请
        </div> -->
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse nav-background" id="navbarSupportedContent" @click="hide">
          <ul class="navbar-nav mr-auto">
            <router-link to="/" v-slot="{ isExactActive }">
              <li :class="{ 'nav-item': true, 'nav-item--active': isExactActive }" @click="toPage('/')">
                <a>首页</a>
              </li>
            </router-link>
            <router-link to="/new" v-slot="{ isExactActive }">
              <li :class="{ 'nav-item': true, 'nav-item--active': isExactActive }" @click="toPage('new')">
                <a>新闻资讯</a>
              </li>
            </router-link>
            <router-link to="/people" v-slot="{ isExactActive }">
              <li :class="{ 'nav-item': true, 'nav-item--active': isExactActive }" @click="toPage('people')">
                <a>专家介绍</a>
              </li>
            </router-link>
            <router-link to="vr-build" v-slot="{ isExactActive }">
              <li :class="{ 'nav-item': true, 'nav-item--active': isExactActive }" @click="toPage('vr-build')">
                <a>VR专业建设</a>
              </li>
            </router-link>
            <router-link to="vr-major" v-slot="{ isExactActive }">
              <li :class="{ 'nav-item': true, 'nav-item--active': isExactActive }" @click="toPage('vr-major')">
                <a>VR专业介绍</a>
              </li>
            </router-link>
            <!-- <router-link to="vr-class" v-slot="{ isExactActive }">
              <li :class="{ 'nav-item': true, 'nav-item--active': isExactActive }" @click="toPage('vr-class')">
                <a>VR实验室建设</a>
              </li>
            </router-link> -->
            <router-link to="vr-teach" v-slot="{ isExactActive }">
              <li :class="{ 'nav-item': true, 'nav-item--active': isExactActive }" @click="toPage('vr-teach')">
                <a>教VR-企业范例</a>
              </li>
            </router-link>
            <router-link to="vr-taught" v-slot="{ isExactActive }">
              <li :class="{ 'nav-item': true, 'nav-item--active': isExactActive }" @click="toPage('vr-taught')">
                <a>VR教-企业范例</a>
              </li>
            </router-link>
            <router-link to="aubot" v-slot="{ isExactActive }">
              <li :class="{ 'nav-item': true, 'nav-item--active': isExactActive }" @click="toPage('aubot')">
                <a>工委会建设</a>
              </li>
            </router-link>
            <router-link to="download" v-slot="{ isExactActive }">
              <li :class="{ 'nav-item': true, 'nav-item--active': isExactActive }" @click="toPage('download')">
                <a>下载中心</a>
              </li>
            </router-link>
            <!-- <router-link to="application" v-slot="{isExactActive}">
              <li :class="{'nav-item':true,'nav-item--active':isExactActive || route === '/application-detail'}" @click="toPage('application')">
                <a>VR应用中心</a>
              </li>
            </router-link> -->
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
import Bus from '@/components/bus.js'
export default {
  name: "Header",
  data() {
    return {
      login: false,
    }
  },
  computed: {
    route() {
      return this.$route.path
    },
  },
  created() {
    this.$http2({
      url: "/getInfo",
    }).then((res) => {
      if (res.code !== 200) {
        this.logout()
      }
    }).catch(() => {
      this.logout()
    });
    this.changeLogin()
    Bus.$on('changeLogin', this.changeLogin);
  },
  beforeDestroy() {
    Bus.$off('changeLogin', this.changeLogin);
  },
  methods: {
    toPage(path) {
      this.$router.push({
        path
      })
    },
    hide() {
      this.$emit('hide')
    },
    showLoginModal() {
      this.$emit('showLoginModal')
    },
    showRegisterModal() {
      this.$emit('showRegisterModal')
    },
    logout() {
      localStorage.removeItem('authToken')
      this.login = false
    },
    changeLogin() {
      this.login = localStorage.getItem('authToken') != null
    }
  }
}
</script>
<style scoped lang="scss">
.vr-header-logo {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.vr-header-nav {
  background: #2870b6;
}

.vr-header-nav-mobile {
  background: rgba(255, 255, 255, 0.4);
}

.vr-header-nav-content {
  max-width: 1200px;
  margin: 0 auto;
}

.vr-header-apply {
  display: flex;
  justify-content: space-between;
  padding: 0 24px;

  &-item {
    width: 130px;
    height: 48px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #666666;
    cursor: pointer;
    border: 1px solid #dadada;

    img {
      width: 24px;
      height: 24px;
    }
  }
}

.vr-header-sign {
  display: flex;
  justify-content: space-between;
  background: #2870b6;
  height: 48px;
  border-radius: 10px;

  &-item {
    padding: 10px 28px;
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    height: 28px;
    line-height: 28px;
    font-weight: 400;
    color: #fff;
    cursor: pointer;
    font-family: Microsoft YaHei;

    &:hover {
      color: #d5d5d5;
    }
  }

  p {
    padding: 10px 0;
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    height: 28px;
    line-height: 28px;
    font-weight: 400;
    color: #fff;
    cursor: pointer;
    font-family: Microsoft YaHei;

  }
}

.vr-header-hadLog {
  display: flex;
  gap: 20;
  height: 48px;
  padding: 10px 0;

  p {
    font-size: 16px;
    height: 28px;
    line-height: 28px;
    font-weight: 400;
    color: #666666;
    padding: 0;
    margin: 0;
    font-family: Microsoft YaHei;
  }

  &-item {
    padding: 0;
    font-size: 16px;
    margin: 0;
    height: 28px;
    line-height: 28px;
    font-weight: 400;
    color: #666666;
    cursor: pointer;
    font-family: Microsoft YaHei;

    &:hover {
      color: #3f3f3f;
      font-weight: 600;
    }
  }
}

.nav-item {
  padding: 12px 24px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
  font-family: Microsoft YaHei;
}

.nav-item--active {
  background: #e71415;
  color: #fff;
}

.vr-header-login {
  font-size: 16px;
  color: #333;
  padding-left: 12px;
  font-family: Microsoft YaHei;

  span {
    display: inline-block;
    padding: 0 5px;
    cursor: pointer;
  }
}
</style>