<template>
  <div class="homeLogin">
    <div class="homeLogin-btn homeLogin-btn-new">
      新用户注册
    </div>
    <div class="homeLogin-btn homeLogin-zhuce">
      我要登陆
    </div>
    <div class="homeLogin-btn homeLogin-quick">
      ---------快速通道---------
    </div>
    <div class="homeLogin-bottom">
      <div class=" homeLogin-bottom-item">
        <img :src="require('@imgs/comMember@3.png')" />
        委员申请
      </div>
      <div class=" homeLogin-bottom-item">
        <img :src="require('@imgs/activityApply@3.png')" />
        活动申请
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'homeLogin',

}
</script>
<style scoped lang="scss">
.homeLogin {
  background: #ffffff;
  border-radius: 4px;
  width: 320px;
  height: 325px;
  padding-top: 40px;
  &-btn {
    width: 272px;
    height: 44px;
    border: 1px solid #dddddd;
    font-size: 20px;
    font-weight: 400;
    line-height: 44px;
    margin: 0 auto;
    text-align: center;
    margin-top: 20px;
  }
  &-new {
    color: #333333;
  }
  &-quick {
    font-size: 20px;
    font-weight: 400;
    color: #0060af;
    text-align: center;
    border: 0;
  }
  &-zhuce {
    color: #fff;
    background: #0862ca;
  }
  &-bottom {
    display: flex;
    justify-content: space-between;
    padding:0 24px;
    &-item {
      width: 130px;
      height: 48px;
      display: flex;
      justify-content: space-around;
      align-items:center;
      color: #666666;
      border:1px solid #DADADA;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}
</style>