<!--
 * @Author: your name
 * @Date: 2021-03-29 16:08:45
 * @LastEditTime: 2021-06-06 23:04:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \vr-portal\src\components\footer.vue
-->
<template>
  <div class="vr-footer">
    <div class="vr-footer-info row">
      <div class="row">
        <router-link to="/" v-slot="{ isExactActive }">
          <div :class="{ 'vr-footer-text': true, 'vr-footer-text--active': isExactActive }" @click="toPage('/')">
            <span>首页</span>
          </div>
        </router-link>
        <router-link to="/new" v-slot="{ isExactActive }">
          <div :class="{ 'vr-footer-text': true, 'vr-footer-text--active': isExactActive }" @click="toPage('/new')">
            <span>新闻资讯</span>
          </div>
        </router-link>
        <router-link to="/people" v-slot="{ isExactActive }">
          <div :class="{ 'vr-footer-text': true, 'vr-footer-text--active': isExactActive }" @click="toPage('/people')">
            <span>专家介绍</span>
          </div>
        </router-link>
        <router-link to="/vr-build" v-slot="{ isExactActive }">
          <div :class="{ 'vr-footer-text': true, 'vr-footer-text--active': isExactActive }" @click="toPage('/vr-build')">
            <span>VR专业建设</span>
          </div>
        </router-link>
        <router-link to="vr-major" v-slot="{ isExactActive }">
          <div :class="{ 'vr-footer-text': true, 'vr-footer-text--active': isExactActive }" @click="toPage('/vr-major')">
            <span>VR专业介绍</span>
          </div>
        </router-link>
        <!-- <router-link to="/vr-class" v-slot="{ isExactActive }">
          <div :class="{ 'vr-footer-text': true, 'vr-footer-text--active': isExactActive }" @click="toPage('/vr-class')">
            <span>VR实验室建设</span>
          </div>
        </router-link> -->
        <router-link to="vr-teach" v-slot="{ isExactActive }">
          <div :class="{ 'vr-footer-text': true, 'vr-footer-text--active': isExactActive }" @click="toPage('vr-teach')">
            <span>教VR-企业范例</span>
          </div>
        </router-link>
        <router-link to="vr-taught" v-slot="{ isExactActive }">
          <div :class="{ 'vr-footer-text': true, 'vr-footer-text--active': isExactActive }" @click="toPage('vr-taught')">
            <span>VR教-企业范例</span>
          </div>
        </router-link>
        <router-link to="aubot" v-slot="{ isExactActive }">
          <div :class="{ 'vr-footer-text': true, 'vr-footer-text--active': isExactActive }" @click="toPage('/aubot')">
            <span>工委会建设</span>
          </div>
        </router-link>
        <router-link to="download" v-slot="{ isExactActive }">
          <div :class="{ 'vr-footer-text': true, 'vr-footer-text--active': isExactActive }" @click="toPage('/download')">
            <span>下载中心</span>
          </div>
        </router-link>
        <!-- <router-link to="/application" v-slot="{isExactActive}">
          <div :class="{'vr-footer-text':true,'vr-footer-text--active':isExactActive}" @click="toPage('/application')">
            <span>VR应用中心</span>
          </div>
        </router-link> -->
      </div>
    </div>
    <div class="vr-footer-cr vr-footer-cr--sm">
      <img :src="require('@imgs/beian.png')" />
      <div style="width:10px;"></div>
      <a href="https://www.beian.gov.cn/portal/recordQuery?token=9d113844-b64b-47f8-85ff-748b7916e8d3">京公网安备
        11011402011361号</a>
      <div style="width:30px;"></div>
      <span>京ICP备2021015329号-1</span>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    toPage(path) {
      this.$router.push({
        path
      })
    }
  }
}
</script>
<style scoped lang="scss">
.vr-footer {
  background: #3a3c3d;
  padding: 24px 0;

  &-info {
    max-width: 1200px;
    padding: 0 24px;
    margin: 0 auto;
  }

  &-title {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    padding-top: 15px;
    padding-bottom: 15px;
    font-family: Microsoft YaHei;
  }

  &-link {
    padding-bottom: 10px;
  }

  &-cr {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #c1c1c1;
    padding: 24px 24px 0 35px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    font-family: Microsoft YaHei;

    img {
      width: 20px;
      height: 20px;
    }

    a {
      color: #c1c1c1;
    }
  }

  &-text {
    font-size: 16px;
    font-weight: 400;
    padding: 0 12px;
    text-align: center;
    color: #c1c1c1;
    position: relative;
    font-family: Microsoft YaHei;

    span {
      color: #c1c1c1;
      text-decoration: none;
      cursor: pointer;
    }

    &--active {
      span {
        color: #fff;
      }
    }

    &:not(:last-child)::after {
      position: absolute;
      display: block;
      content: "";
      width: 1px;
      height: 18px;
      right: 0;
      border: 1px solid #c1c1c1;
      transform: scale(0.5, 1);
      top: 2px;
    }
  }
}
</style>