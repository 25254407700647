<!--
 * @Author: your name
 * @Date: 2021-06-01 19:40:44
 * @LastEditTime: 2021-06-20 23:07:17
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \vr-portal\src\views\people\people-item.vue
-->
<template>
  <div class="people" @click="toDetail">
    <img :src="info.expertPic" class="position-absolute" style="top:0;bottom:0;left:0;right:0;" />
    <!-- style="top:5px;bottom:0;left:calc((215px - 171.4285714px) / 2);right:0;" /> -->
    <div class="people-name">{{ info.expertName }}</div>
    <div class="people-tip">
      <div style="height:12px;"></div>
      <h3>{{ info.expertName }}</h3>
      <h4>{{ info.expertIntroduction }}</h4>
    </div>

  </div>
</template>
<script>
export default {
  name: 'peopleItem',
  props: {
    info: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    toDetail() {
      this.$router.push({
        path: '/people-detail',
        query: {
          id: this.info.expertId
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.people {
  width: 215px;
  height: 285px;
  background: #fff;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  margin-left: 13px;

  // img {
  //   height: 240px;
  //   width: 171.4285714px;
  //   border-radius: 50% / 50%;
  //   overflow: hidden;
  // }
  img {
    width: 100%;
  }

  &:hover &-tip {
    height: 100%;
  }

  &-tip {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
    transition: height 0.6s;
    padding-left: 12px;

    h4 {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 10;
      white-space: normal;
      font-size: 14px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      font-family: Microsoft YaHei;
    }

    h3 {
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      line-height: 25px;
      box-sizing: border-box;
      font-family: Microsoft YaHei;
    }
  }

  &-name {
    height: 40px;
    line-height: 40px;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 18px;
    font-weight: 400;
    color: #666666;
    line-height: 40px;
    font-family: Microsoft YaHei;
  }
}
</style>