<template>
  <div class="vrBuild">
    <div class="vrBuild-tab">
      <div :class="{
        'vrBuild-tab-item': true,
        'vrBuild-tab-item--active': vrTabItem === '人才培养研究',
      }" @click="tabChange('人才培养研究')">
        人才培养研究
      </div>
      <div :class="{
        'vrBuild-tab-item': true,
        'vrBuild-tab-item--active': vrTabItem === 'VR教材建设',
      }" @click="tabChange('VR教材建设')">
        VR教材建设
      </div>
      <div :class="{
        'vrBuild-tab-item': true,
        'vrBuild-tab-item--active': vrTabItem === 'VR师资培训',
      }" @click="tabChange('VR师资培训')">
        VR师资培训
      </div>
      <div :class="{
        'vrBuild-tab-item': true,
        'vrBuild-tab-item--active': vrTabItem === 'VR专业课程教学平台',
      }" @click="tabChange('VR专业课程教学平台')">
        VR专业课程教学平台
      </div>
    </div>
    <!-- <template v-if="vrTabItem === '人才培养研究'">
      <div style="height: 20px"></div>
      <div class="vrBuild-link" v-for="(item, index) in links" :key="index" @click="downLoad(item.fileUrl)">
        <div class="vrBuild-link-icon">
          <img :src="require('@imgs/pdf@3x.png')" />
        </div>
        <div class="vrBuild-link-title l-1-overflow">{{ item.title }}</div>
        <div class="vrBuild-link-icon">
          <img style="width: 18px" :src="require('@imgs/downLoad.png')" />
        </div>
      </div>
    </template> -->
    <template v-if="vrTabItem === '人才培养研究'">
      <div class="vrBuild-tab-content">
        <div class="vrBuild-tab-box col-sm-4 col-12" v-for="(item, index) in vrItems" :key="key[vrTabItem] + index">
          <vr-item2 class="vrBuild-tabItem" :info="item" :type="vrTabItem" :key="key[vrTabItem] + index"></vr-item2>
        </div>
      </div>
    </template>
    <template v-if="[
      'VR教材建设',
      'VR师资培训',
      'VR专业课程教学平台',
    ].includes(vrTabItem)
      ">
      <div class="vrBuild-tab-content">
        <div class="vrBuild-tab-box col-sm-3 col-12" v-for="(item, index) in vrItems" :key="key[vrTabItem] + index">
          <vr-item class="vrBuild-tabItem" :info="item" :type="vrTabItem" :key="key[vrTabItem] + index"></vr-item>
        </div>
      </div>
    </template>
    <page v-if="isPage" :total="total" @pageChange="getList"></page>
  </div>
</template>
<script>
import vrItem from "./components/vr-item.vue";
import vrItem2 from "./components/vr-item2.vue";
import page from "@components/page/page.vue";
export default {
  name: "vrBuild",
  props: {
    isPage: {
      type: Boolean,
      default: true,
    },
    pageSize: {
      type: Number,
      default: 8,
    },
  },
  components: {
    page,
    vrItem,
    vrItem2
  },
  created() {
    this.getList(1);
  },
  data() {
    return {
      vrItems: [],
      vrTabItem: "人才培养研究",
      links: [],
      total: 0,
      key: {
        VR教材建设: "a",
        VR师资培训: "b",
        VR专业课程教学平台: "c",
      },
    };
  },
  methods: {
    tabChange(item) {
      this.vrTabItem = item;
      this.getList(1);
    },
    getList(pageNum) {
      let url = "";
      if (this.vrTabItem === "人才培养研究") {
        url = "/train/list";
      } else if (this.vrTabItem === "VR教材建设") {
        url = "/construction/list";
      } else if (this.vrTabItem === "VR师资培训") {
        url = "/training/list";
      } else {
        url = "/platform/list";
      }
      if (pageNum === 1) {
        this.links = [];
        this.vrItems = [];
      }
      this.$http({
        url,
        params: {
          pageNum,
          pageSize: (this.vrTabItem === "人才培养研究") ? 6 : this.pageSize,
        },
      }).then((res) => {
        this.vrItems = res.rows || [];
        this.total = res.total;
      });
    },
    downLoad(link) {
      let a = document.createElement("a");
      a.href = link;
      a.click();
    },
  },
};
</script>
<style scoped lang="scss">
.vrBuild {
  &-tab {

    //display: flex;
    &-box {
      display: flex;
      justify-content: center;
    }

    &-item {
      padding: 5px 16px;
      font-size: 16px;
      border: 1px solid #eee;
      color: #666666;
      cursor: pointer;
      margin-bottom: 30px;
      display: inline-block;
      font-family: Microsoft YaHei;
    }

    &-item--active {
      background: #2870b6;
      color: #fff;
      border: 0;
    }
  }

  &-tab-content {
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    min-height: 247px;
  }

  &-tabItem {
    margin-top: 15px;
  }

  &-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 62px;
    border-bottom: 1px solid #eeeeee;

    &-icon {
      img {
        width: 32px;
      }
    }

    &-title {
      flex: 1;
      padding-left: 15px;
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      font-family: Microsoft YaHei;
    }

    &-title:active,
    &-title:hover {
      color: #2870b6;
    }
  }
}
</style>
