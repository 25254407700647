<template>
    <div class="modal fade" id="registerModal" tabindex="-1" aria-labelledby="registerModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="registerModal-content">
                <div class="registerModal-header">
                    <div class="registerModal-header-line">
                        <p></p>
                        <button type="button" class="close registerModal-header-line-close" data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <p class="registerModal-header-title" id="registerModalLabel">注册</p>
                </div>
                <div class="registerModal-body">
                    <form @submit.prevent="submitForm" class="registerModal-body-form">
                        <p class="registerModal-body-form-title">账号信息</p>
                        <div class="registerModal-body-form-group">
                            <label for="username">账号</label>
                            <input type="text" id="username" v-model="username" required placeholder="请输入手机号或邮箱">
                        </div>
                        <div class="registerModal-body-form-line"></div>
                        <div class="registerModal-body-form-group">
                            <label for="password">密码</label>
                            <input type="password" id="password" v-model="password" required placeholder="请设置密码 包含数字与字母">
                        </div>
                        <div class="registerModal-body-form-line"></div>
                        <div class="registerModal-body-form-group">
                            <label for="confirmPassword">确认密码</label>
                            <input type="password" id="confirmPassword" v-model="confirmPassword" required
                                placeholder="请输入设置密码">
                        </div>
                        <div class="registerModal-body-form-line"></div>
                        <p class="registerModal-body-form-title">个人信息</p>
                        <div class="registerModal-body-form-group">
                            <label for="realName">姓名</label>
                            <input type="text" id="realName" v-model="realName" required placeholder="请输入真实姓名">
                        </div>
                        <div class="registerModal-body-form-line"></div>
                        <div class="registerModal-body-form-group">
                            <label for="company">单位</label>
                            <input type="text" id="company" v-model="company" required placeholder="请输入所在单位名称">
                        </div>
                        <div class="registerModal-body-form-line"></div>
                        <div class="registerModal-body-form-group">
                            <label for="email">邮箱</label>
                            <input type="text" id="email" v-model="email" required placeholder="请输入邮箱号">
                        </div>
                        <div class="registerModal-body-form-line" style="margin-bottom: 10px;"></div>
                        <button type="submit" class="btn">立即注册</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Bus from '@/components/bus.js'
export default {
    name: "registerModal",
    data() {
        return {
            isVisible: true,
            username: '',
            password: '',
            confirmPassword: '',
            realName: '',
            company: '',
            email: '',
        }
    },
    created() {
        Bus.$on('showRegisterModal', this.showModal);
    },
    beforeDestroy() {
        Bus.$off('showRegisterModal', this.showModal);
    },
    methods: {
        submitForm() {
            if (this.password.length < 6) {
                this.$toast.error('密码必须大于等于6位!', {
                    timeout: 1500
                });
                return;
            }
            // 检查密码是否包含数字和字母
            const passwordRegEx = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
            if (!passwordRegEx.test(this.password)) {
                this.$toast.error('密码必须包含数字和字母!', {
                    timeout: 1500
                });
                return;
            }
            // 检查密码和确认密码是否相同
            if (this.password !== this.confirmPassword) {
                this.$toast.error('密码和确认密码不匹配!', {
                    timeout: 1500
                });
                return;
            }
            //检查账号是否是地为邮箱或者中国手机号
            const phoneRegEx = /^1[3-9]\d{9}$/;
            const emailRegEx = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/;
            if (!phoneRegEx.test(this.username) && !emailRegEx.test(this.username)) {
                this.$toast.error('账号必须为邮箱或手机号!', {
                    timeout: 1500
                });
                return;
            }
            //检查邮箱是否是地为邮箱
            if (!emailRegEx.test(this.email)) {
                this.$toast.error('邮箱格式不正确!', {
                    timeout: 1500
                });
                return;
            }
            const formData = {
                userName: this.username,
                password: this.password,
                realName: this.realName,
                company: this.company,
                email: this.email,
                status: "0",
                postIds: [],
                roleIds: [
                    103
                ],
            };

            this.$http2({
                url: "/system/user",
                data: formData,
                method: 'post'
            }).then((res) => {
                if (res.code === 500) {
                    this.$toast.error(res.msg, {
                        timeout: 1500
                    });
                } else {
                    this.$toast.info("注册成功，请前往登录", {
                        timeout: 1000
                    });
                    $('#registerModal').modal('hide');
                    Bus.$emit('showNormalModal');
                }
            }).catch((err) => {
                this.$toast.error(err.toString(), {
                    timeout: 1500
                });
            });
        },
        showModal() {
            $('#registerModal').modal('show');
        },
        hideModal() {
            $('#registerModal').modal('hide');
        }
    }
}
</script>
<style scoped lang="scss">
.modal-dialog {
    margin-top: 100px;
    background: #FFFFFF;
    box-shadow: 0px 9px 22px 7px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    max-width: 700px;

    .registerModal-content {
        padding: 24px;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        outline: 0;
    }
}

.registerModal-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-line {
        display: flex;
        width: 100%;
        justify-content: space-between;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 16px;
        color: #5B5959;
        line-height: auto;

        &-close {
            margin: -24px -24px 0 0;
            width: 48px;
            height: 48px;
            background: #FFFFFF;
            box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.13);
            border-radius: 50%;
            border: 1px solid #EFEEEE;
        }
    }

    &-title {
        font-size: 32px;
        line-height: normal;
        font-weight: 600;
        color: #595555;
        font-family: Microsoft YaHei;
        margin: 0;

        &::after {
            content: "";
            display: block;
            width: 62px;
            height: 6px;
            background: #2770B5;
            border-radius: 3px;
        }
    }
}

.registerModal-body {
    width: 100%;
    padding: 40px;
    box-sizing: border-box;

    &-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-title {
            font-family: Microsoft YaHei;
            font-weight: 500;
            font-size: 22px;
            width: 100%;
            line-height: normal;
            color: #2770B5;
            margin: 0 0 20px;
        }

        &-group {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            position: relative;

            label {
                width: 90px;
                font-family: Microsoft YaHei;
                font-weight: 500;
                font-size: 20px;
                line-height: normal;
                color: #595555;
                margin: 0;
            }

            input {
                flex: 1;
                margin: 0 20px 0 50px;
                border: none;
                background: none;
                outline: none;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #3c3c3c;
                padding: 0;

                &::placeholder {
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    font-size: 16px;
                    color: #C1BCBC;
                    letter-spacing: normal;
                    margin: 0;
                    padding: 0;
                }
            }

            img {
                position: absolute;
                right: 20px;
                height: 36px;
                width: 96px;
                top: calc(50% - 19px);
                cursor: pointer;
            }
        }

        .forget {
            line-height: normal;
            color: #2770B5;
            font-family: Microsoft YaHei;
            margin: 0;
            width: 100%;
            text-align: end;
            font-weight: 400;
            font-size: 15px;
            cursor: pointer;

            &:hover {
                font-weight: 600;
                color: #2983d7;
            }
        }

        .btn {
            margin-top: 30px;
            width: 200px;
            height: 50px;
            background: #2770B5;
            border-radius: 25px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 18px;
            color: #FFFFFF;
            box-shadow: inset 0px 7px 14px 0px rgba(0, 0, 0, 0.46);

            &:hover {
                box-shadow: 0px 7px 14px 0px rgba(0, 0, 0, 0.46);
            }

            &:active {
                box-shadow: none;
            }
        }

        &-line {
            display: block;
            margin-bottom: 30px;
            margin-top: 5px;
            width: 100%;
            height: 3px;
            background: #B6B5B0;
            opacity: 0.34;

        }
    }
}
</style>