<template>
  <div class="application-item" @click="toDetail">
    <div class="application-img">
      <img :src="info.cover1" />
    </div>
    <h3>{{info.title}}</h3>
    <p class="l-2-overflow">{{info.productDetail | getContent}}</p>
    <div style="display:flex;justify-content:center;">
      <div class="application-item-button">
        查看详情
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "applicationItem",
  props: {
    info: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  filters: {
    getContent (v) {
      let dom = document.createElement('div')
      dom.innerHTML = v
      return dom.innerText
    }
  },
  methods: {
    toDetail () {
      this.$router.push({
        path: '/application-detail',
        query:{
          id: this.info.id
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.application-item {
  width: 270px;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #eeeeee;
  padding-bottom: 27px;
  display: flex;
  flex-direction: column;
  align-items: left;
  p {
    font-size: 13px;
    font-weight: 400;
    color: #999999;
    text-align: left;
    padding: 0 10px;
    line-height: 20px;
    min-height: 44px;
  }
  h3 {
    font-size: 13px;
    font-weight: 600;
    color: #666666;
    line-height: 18px;
    padding: 10px;
  }
}
.application-img {
  width: 270px;
  height: 129px;
  overflow: hidden;
  img {
    width: 100%;
  }
}
.application-item:hover {
  box-shadow: 0px 1px 11px 0px rgba(0, 0, 0, 0.2);
}
.application-item-button {
  padding: 5px 10px;
  color: #999999;
  font-weight: 400;
  font-size: 13px;
  border: 1px solid #eee;
  display: inline-block;
}
.application-item-button:hover {
  border: 0;
  color: #fff;
  background: #2870b6;
}
</style>